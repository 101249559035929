import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import HeroSection from "../components/HeroSection"
import drinkmachine from "../images/drinkmachine.jpg"
import vending from "../images/vending2.jpg"
import vending2 from "../images/vending3.jpg"
import lightbulb from "../images/lightbulb.jpg"

export default function Home(props) {
  return (
    <Layout>
      <SEO
        title="Home"
        description="Stars and Stripes Vending is an innovative vending company in the Greater Boston Area of Massachusetts. We specialize in open vending and traditional vending machines. All of our services are backed by unwavering customer support and keen attention to detail. We make sure you get the best quality product without the hassle." />
      <Hero />
      <HeroSection
        img={vending}
        order="-9999"
        title="Full service vending without the hassle"
        cta="Our Services"
        link="/services"
      />
      <HeroSection
        img={vending2}
        order="9999"
        title="Vending where"
        title2="you need it"
        cta="See Locations"
        link="/locations"
      />
      <HeroSection
        img={drinkmachine}
        order="-9999"
        title="All the products"
        title2="you love"
        cta="See Products"
        link="/products"
      />
      <HeroSection
        img={lightbulb}
        order="9999"
        title="Innovating the"
        title2="industry"
        cta="About us"
        link="/about"
      />
      <Footer />

    </Layout>
  )
}
