import React from "react"
import HeroImage from "../images/vendingmachines.jpg"
import NavBar from "../components/NavBar"


function Landing() {
    return (
        <div className="bg-fill bg-right h-full pb-32 brightenMobile sm:resetContrast" style={{ backgroundImage: `url(${HeroImage})`}}>
            <NavBar
                bg="bg-transparent"
            />
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-32">
                <div className="flex">
                    <div className="flex flex-col w-full xl:w-1/2 my-32 sm:my-8">
                        <h1 className="text-6xl tracking-tight font-extrabold text-white font-display">
                            Vending beyond 
                            <br />the machine
                        </h1>
                        {/* <h2 className="mt-3 max-w-md text-lg font-display text-secondary sm:text-xl md:mt-5 md:max-w-3xl font-light leading-10">
                            Stars and Stripes Vending is an innovative vending company in the Greater Boston Area. We specialize in open vending and traditional vending machines. All of our services are backed by unwavering customer support and keen attention to detail. We make sure you get the best quality product without the hassle.
            </h2> */}
                        <a href="/services" aria-label="Get started button" alt="Get Started button" className="bg-yellow-300 text-black font-bold mt-8 tracking-tight w-48 shadow-md font-body text-center py-3 px-8 rounded">
                            Get Started
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing