import React from "react"

export default function HeroSection(props) {
    return (
        <div className="bg-black h-full">
            <div className="flex flex-col sm:flex-row">
                <div className="flex flex-col w-full sm:w-2/5 h-screen bg-cover px-8 py-8 brightenMobile sm:resetContrast" style={{ backgroundImage: `url(${props.img})`, order: props.order}}>
                    <h1 className="block sm:hidden text-6xl tracking-tight font-extrabold text-white font-display sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                        {props.title} <br/>
                        {props.title2}
                    </h1>
                    <a href={props.link} aria-label="Section Button" alt="Section Button" className="block sm:hidden bg-yellow-300 text-black font-bold mt-8 tracking-tight w-48 shadow-md font-body text-center py-3 px-8 rounded">
                        {props.cta}
                    </a>
                </div>
                <div className="flex flex-col w-2/5 max-w-7xl mx-auto px-4 sm:px-6 xl:px-32 sm:py-32">
                    <h1 className="hidden sm:block text-4xl tracking-tight leading-10 font-extrabold text-white font-display sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                        {props.title} <br/>
                        {props.title2}
                    </h1>
                    <a href={props.link} aria-label="Section Button" alt="Section Button" className="hidden sm:block bg-yellow-300 text-black font-bold mt-8 tracking-tight w-48 shadow-md font-body text-center py-3 px-8 rounded">
                        {props.cta}
                    </a>
                </div>
            </div>
        </div>
    )
}